/* eslint-disable */

import { NavLink, useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.toLowerCase().includes(routeName.toLowerCase());
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[]) => {
    return routes
      .filter((route) => !route.hidden)
      .map((route: RoutesType, index: number) => {
        if (route.children) {
          return (
            <AccordionItem py="5px" ps="10px" border={"0"} key={index}>
              <AccordionButton pl="0px" pt="0px" border={"0"}>
                <Box>
                  <HStack spacing={"26px"} py="5px" ps="10px" pl="0px">
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box color={textColor} me="18px">
                        {route.icon}
                      </Box>
                      <Text me="auto" color={textColor} fontWeight={"normal"}>
                        {route.name}
                      </Text>
                    </Flex>
                  </HStack>
                </Box>
              </AccordionButton>
              <AccordionPanel pb={4}>
                {route.children.map((childRoute, childIndex) => (
                  <NavLink
                    key={childIndex}
                    to={childRoute.layout + childRoute.path}
                  >
                    {route.icon ? (
                      <Box>
                        <HStack
                          spacing={
                            activeRoute(childRoute.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py="5px"
                          ps="10px"
                        >
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              color={
                                activeRoute(childRoute.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me="18px"
                            >
                              {childRoute.icon}
                            </Box>
                            <Text
                              me="auto"
                              color={
                                activeRoute(childRoute.path.toLowerCase())
                                  ? activeColor
                                  : textColor
                              }
                              fontWeight={
                                activeRoute(childRoute.path.toLowerCase())
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              {childRoute.name}
                            </Text>
                          </Flex>
                          <Box
                            h="36px"
                            w="4px"
                            bg={
                              activeRoute(childRoute.path.toLowerCase())
                                ? brandColor
                                : "transparent"
                            }
                            borderRadius="5px"
                          />
                        </HStack>
                      </Box>
                    ) : (
                      <Box>
                        <HStack
                          spacing={
                            activeRoute(childRoute.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py="5px"
                          ps="10px"
                        >
                          <Text
                            me="auto"
                            color={
                              activeRoute(childRoute.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight={
                              activeRoute(childRoute.path.toLowerCase())
                                ? "bold"
                                : "normal"
                            }
                          >
                            {childRoute.name}
                          </Text>
                          <Box
                            h="36px"
                            w="4px"
                            bg="brand.400"
                            borderRadius="5px"
                          />
                        </HStack>
                      </Box>
                    )}
                  </NavLink>
                ))}
              </AccordionPanel>
            </AccordionItem>
          );
        } else {
          if (
            (route.layout === "/dashboard" ||
              route.layout === "/auth" ||
              route.layout === "/admin") &&
            route.hidden !== true
          ) {
            return (
              <NavLink key={index} to={route.layout + route.path}>
                {route.icon ? (
                  <Box>
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                      }
                      py="5px"
                      ps="10px"
                    >
                      <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeIcon
                              : textColor
                          }
                          me="18px"
                        >
                          {route.icon}
                        </Box>
                        <Text
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : textColor
                          }
                          fontWeight={
                            activeRoute(route.path.toLowerCase())
                              ? "bold"
                              : "normal"
                          }
                        >
                          {route.name}
                        </Text>
                      </Flex>
                      <Box
                        h="36px"
                        w="4px"
                        bg={
                          activeRoute(route.path.toLowerCase())
                            ? brandColor
                            : "transparent"
                        }
                        borderRadius="5px"
                      />
                    </HStack>
                  </Box>
                ) : (
                  <Box>
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                      }
                      py="5px"
                      ps="10px"
                    >
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : inactiveColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        {route.name}
                      </Text>
                      <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                    </HStack>
                  </Box>
                )}
              </NavLink>
            );
          }
        }
      });
  };

  return <Accordion allowToggle>{createLinks(routes)}</Accordion>;
}

export default SidebarLinks;

import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CompanyDtoWithFiles } from "../../../../generated-client/model/company-dto-with-files";
import { getAllCompaniesWithKycAdmin } from "../../../../services/company";
import { CompanySubscriptionOption } from "../typings";

const resolveIsSubscribedFlag = (
  subscriptionType: CompanySubscriptionOption
) => {
  if (subscriptionType === CompanySubscriptionOption.ALL) {
    return undefined;
  }
  return subscriptionType === CompanySubscriptionOption.SUBSCRIBED;
};

export const useFetchCompanies = () => {
  const [companies, setCompanies] = useState<CompanyDtoWithFiles[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async (
    skip = 0,
    take = 30,
    companyNameSearch: string = undefined,
    selectedSubscription: CompanySubscriptionOption = undefined
  ) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllCompaniesWithKycAdmin(accessToken, {
        skip,
        take,
        companyNameSearch,
        isSubscribed: resolveIsSubscribedFlag(selectedSubscription),
      });
      setCompanies(data.companies);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { companies, accessToken, fetchData };
};

/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateDirectPaymentRequest { 
    country: string;
    amount: number;
    amountCurrency: CreateDirectPaymentRequest.AmountCurrencyEnum;
    amountToPay: number;
    amountToPayCurrency: CreateDirectPaymentRequest.AmountToPayCurrencyEnum;
    paymentMethod: CreateDirectPaymentRequest.PaymentMethodEnum;
    type: CreateDirectPaymentRequest.TypeEnum;
    termsAccepted: boolean;
    fileId?: string;
    recipientId: string;
    exchangeRate: number;
    paymentRequestId?: string;
}
export namespace CreateDirectPaymentRequest {
    export type AmountCurrencyEnum = 'USD' | 'NGN';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Ngn: 'NGN' as AmountCurrencyEnum
    };
    export type AmountToPayCurrencyEnum = 'USD' | 'NGN';
    export const AmountToPayCurrencyEnum = {
        Usd: 'USD' as AmountToPayCurrencyEnum,
        Ngn: 'NGN' as AmountToPayCurrencyEnum
    };
    export type PaymentMethodEnum = 'BANK_TRANSFER' | 'CASH_DEPOSIT' | 'FLEX_PAY';
    export const PaymentMethodEnum = {
        BankTransfer: 'BANK_TRANSFER' as PaymentMethodEnum,
        CashDeposit: 'CASH_DEPOSIT' as PaymentMethodEnum,
        FlexPay: 'FLEX_PAY' as PaymentMethodEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'PAY_NOW_SUPPLIER' | 'PAY_NOW_OTHER' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        PayNowSupplier: 'PAY_NOW_SUPPLIER' as TypeEnum,
        PayNowOther: 'PAY_NOW_OTHER' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
}



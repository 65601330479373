import { callGet, callPatch, callPost } from "../../lib/api-private";
import { RecipientDto } from "../../generated-client/model/recipient-dto";
import { UpdateRecipientRequest } from "generated-client/model/update-recipient-request";

export interface CreateRecipientRequest {
  name: string;
  bankName: string;
  accountNumber: string;
  country: string;
}

export const recipientsService = {
  async getRecipients(accessToken: string): Promise<RecipientDto[]> {
    const response = (await callGet(accessToken, "/recipients")) as {
      recipients: RecipientDto[];
    };
    return response.recipients;
  },

  async getRecipientById(
    accessToken: string,
    id: string
  ): Promise<RecipientDto> {
    const response = (await callGet(
      accessToken,
      `/recipients/${id}`
    )) as RecipientDto;
    return response;
  },

  async createRecipient(
    accessToken: string,
    data: CreateRecipientRequest
  ): Promise<RecipientDto> {
    const response = (await callPost(
      accessToken,
      "/recipients",
      data
    )) as RecipientDto;
    return response;
  },

  async updateRecipient(
    accessToken: string,
    data: UpdateRecipientRequest
  ): Promise<RecipientDto> {
    const response = (await callPatch(
      accessToken,
      `/recipients`,
      data
    )) as RecipientDto;
    return response;
  },

  // Helper function to format recipient display name
  formatRecipientDisplay(recipient: RecipientDto): string {
    return `${recipient.name} *${recipient.accountNumber.slice(-5)}`;
  },
};

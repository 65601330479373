import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getCompany } from "../../services/company";

const DefaultRedirect = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isUserNew, setIsUserNew] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const company = await getCompany(accessToken);

        // User is considered new if these fields are not filled
        const isNew =
          !company.company.address &&
          !company.company.name &&
          !company.company.registrationNumber;
        setIsUserNew(isNew);
      } catch (error) {
        console.error("Error checking user status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return null; // or a loading spinner
  }

  if (isUserNew) {
    return <Navigate to="/dashboard/profile" />;
  }

  return <Navigate to="/dashboard/pay" />;
};

export default DefaultRedirect;

/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { RecipientDto } from './recipient-dto';


export interface PaymentDto { 
    id: string;
    amount: number;
    amountToPay: number;
    paymentMethod: PaymentDto.PaymentMethodEnum;
    exchangeRate: number;
    amountCurrency: PaymentDto.AmountCurrencyEnum;
    amountToPayCurrency: PaymentDto.AmountToPayCurrencyEnum;
    status: PaymentDto.StatusEnum;
    recipient: RecipientDto;
    userId: string;
    companyId: string;
    flexPayId?: string | null;
    files?: Array<FileDto>;
    createdAt?: string;
    updatedAt?: string;
}
export namespace PaymentDto {
    export type PaymentMethodEnum = 'BANK_TRANSFER' | 'CASH_DEPOSIT' | 'FLEX_PAY';
    export const PaymentMethodEnum = {
        BankTransfer: 'BANK_TRANSFER' as PaymentMethodEnum,
        CashDeposit: 'CASH_DEPOSIT' as PaymentMethodEnum,
        FlexPay: 'FLEX_PAY' as PaymentMethodEnum
    };
    export type AmountCurrencyEnum = 'USD' | 'NGN';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Ngn: 'NGN' as AmountCurrencyEnum
    };
    export type AmountToPayCurrencyEnum = 'USD' | 'NGN';
    export const AmountToPayCurrencyEnum = {
        Usd: 'USD' as AmountToPayCurrencyEnum,
        Ngn: 'NGN' as AmountToPayCurrencyEnum
    };
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'REJECTED' | 'PAYMENT_SUBMITTED' | 'INVOICE_SENT' | 'REPAID' | 'DECLINED' | 'PAST_DUE' | 'REPAID_LATE';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        PaymentSubmitted: 'PAYMENT_SUBMITTED' as StatusEnum,
        InvoiceSent: 'INVOICE_SENT' as StatusEnum,
        Repaid: 'REPAID' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum,
        PastDue: 'PAST_DUE' as StatusEnum,
        RepaidLate: 'REPAID_LATE' as StatusEnum
    };
}



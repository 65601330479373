import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { ProductDto } from "generated-client/model/product-dto";

interface CardLayoutProps {
  products: ProductDto[];
  handleDeleteProduct: (id: string) => void;
  handleOpenEditProduct: (product: ProductDto, status: boolean) => void;
}

export default function CardLayout({
  products,
  handleDeleteProduct,
  handleOpenEditProduct,
}: CardLayoutProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {products?.map((product) => (
        <Box
          key={product.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text fontSize="xl">{product.name}</Text>

          <Box mb={4} fontSize="0.9em">
            <Text>
              Stock Availability: {product.stockAvailability.replace(/_/g, " ")}
            </Text>

            <Flex wrap={"wrap"} gap={2} mt={3}>
              <Button
                colorScheme="blue"
                size="sm"
                borderRadius={4}
                onClick={() => handleOpenEditProduct(product, true)}
              >
                Update
              </Button>

              <Button
                colorScheme="red"
                onClick={() => handleDeleteProduct(product.id)}
                size="sm"
                borderRadius={4}
              >
                Delete
              </Button>
            </Flex>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

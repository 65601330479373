import { Flex, Input, Select, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import {
  updateCompanyCreditLimitAdmin,
  updateCompanyKycStatusAdmin,
} from "../../../services/company";
import { UpdateCompanyKycStatusRequest } from "../../../generated-client/model/update-company-kyc-status-request";
import { useFetchCompanies } from "./hooks/FetchCompanies";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import Pagination from "../sharedComponents/pagination";
import { CompanySubscriptionOption } from "./typings";
import StatusEnum = UpdateCompanyKycStatusRequest.StatusEnum;
import CountryEnum = CompanyDto.CountryEnum;

export default function Companies() {
  const { companies, fetchData, accessToken } = useFetchCompanies();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 30;

  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const [errorMessages] = useState<{
    [requestId: string]: string;
  }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<CompanySubscriptionOption>(CompanySubscriptionOption.ALL);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, selectedCountry]);

  const filteredCompanies = companies.filter((c) => {
    if (debouncedSearchTerm) {
      return (
        c.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) &&
        (selectedCountry === "All" || c.country === selectedCountry)
      );
    }
    return selectedCountry === "All" || c.country === selectedCountry;
  });

  async function handleStatusChange(companyId: string, newStatus: string) {
    try {
      await updateCompanyKycStatusAdmin(accessToken, {
        id: companyId,
        status: newStatus as StatusEnum,
      });

      setTimeout(() => {
        fetchData(
          (currentPage - 1) * pageSize,
          pageSize,
          debouncedSearchTerm,
          subscriptionStatus
        );
      }, 500);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  async function handleCreditLimitUpdate(
    companyId: string,
    creditLimit: number
  ) {
    try {
      await updateCompanyCreditLimitAdmin(accessToken, {
        id: companyId,
        limit: creditLimit,
      });

      setTimeout(() => {
        fetchData(
          (currentPage - 1) * pageSize,
          pageSize,
          debouncedSearchTerm,
          subscriptionStatus
        );
      }, 1000);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  useEffect(() => {
    fetchData(
      (currentPage - 1) * pageSize,
      pageSize,
      debouncedSearchTerm,
      subscriptionStatus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchTerm, selectedCountry, subscriptionStatus]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {" "}
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by company name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Select
          onChange={(e) => setSelectedCountry(e.target.value)}
          value={selectedCountry}
          w="200px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        >
          <option value="All">All</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Kenya">Kenya</option>
          {/* Add more options here */}
        </Select>
        <Select
          onChange={(e) =>
            setSubscriptionStatus(e.target.value as CompanySubscriptionOption)
          }
          value={subscriptionStatus}
          w="200px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        >
          <option value={CompanySubscriptionOption.ALL}>All</option>
          <option value={CompanySubscriptionOption.SUBSCRIBED}>
            Subscription plan
          </option>
          {/*<option value={CompanySubscriptionOption.NOT_SUBSCRIBED}>Not subscribed</option>*/}
          {/* Add more options here */}
        </Select>
      </Flex>
      {isTableLayout ? (
        <TableComponent
          companies={filteredCompanies}
          accessToken={accessToken}
          fetchData={fetchData}
          handleStatusChange={handleStatusChange}
          handleCreditLimitUpdate={handleCreditLimitUpdate}
          errorMessages={errorMessages}
          filterData={{
            country:
              selectedCountry === "All"
                ? null
                : (selectedCountry as CountryEnum),
            subscriptionStatus,
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
            companyNameSearch: debouncedSearchTerm,
          }}
        />
      ) : (
        <CardLayout
          companies={filteredCompanies}
          accessToken={accessToken}
          fetchData={fetchData}
          handleStatusChange={handleStatusChange}
          handleCreditLimitUpdate={handleCreditLimitUpdate}
          errorMessages={errorMessages}
          filterData={{
            country:
              selectedCountry === "All"
                ? null
                : (selectedCountry as CountryEnum),
            subscriptionStatus,
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
            companyNameSearch: debouncedSearchTerm,
          }}
        />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={filteredCompanies.length}
        onPageChange={handlePageChange}
      />
    </>
  );
}

import { Box, Button, Text, Input, InputGroup } from "@chakra-ui/react";
import { downloadFile } from "services/file";
import { CompanyDtoWithFiles } from "../../../../generated-client/model/company-dto-with-files";
import { FileDto } from "../../../../generated-client/model/file-dto";
import KycStatusEnum = CompanyDtoWithFiles.KycStatusEnum;
import TypeEnum = FileDto.TypeEnum;
import { formatCurrency } from "../../../../lib/utilities";
import { useEffect, useState } from "react";
import { updateCompanyLatestPaymentDateAdmin } from "services/company";

interface CardLayoutProps {
  companies: CompanyDtoWithFiles[];
  accessToken: string;
  handleStatusChange: (companyId: string, newStatus: string) => void;
  handleCreditLimitUpdate: (companyId: string, creditLimit: number) => void;
  fetchData: any;
  errorMessages: { [requestId: string]: string };
  filterData?: {
    subscriptionStatus: string;
    country: string;
    skip: number;
    take: number;
    companyNameSearch: string;
  };
}

interface SubscriptionDates {
  [key: string]: string | null;
}

export default function CardLayout({
  companies,
  accessToken,
  handleStatusChange,
  handleCreditLimitUpdate,
  errorMessages,
  fetchData,
  filterData,
}: CardLayoutProps) {
  const [subscriptionDates, setSubscriptionDates] = useState<SubscriptionDates>(
    {}
  );

  const getFileText = (fileType: TypeEnum, index: number) => {
    if (fileType === TypeEnum.ProcessedBankStatement) {
      return "Bank statement CSV";
    } else if (fileType === TypeEnum.ProofOfUserSubscriptionPayment) {
      return "Proof of subscription";
    } else {
      return index;
    }
  };

  const handleSubscriptionDateChange = async (
    companyId: string,
    newDate: string
  ) => {
    setSubscriptionDates((prevDates) => ({
      ...prevDates,
      [companyId]: newDate,
    }));

    try {
      await updateCompanyLatestPaymentDateAdmin(accessToken, {
        // @ts-ignore
        subscriptionDate: new Date(newDate),
        id: companyId,
      });

      fetchData(
        filterData.skip,
        filterData.take,
        filterData.companyNameSearch,
        filterData.country,
        filterData.subscriptionStatus
      );
    } catch (err) {
      console.error("Failed to update company payment date", err);
    }
  };

  useEffect(() => {
    if (companies.length) {
      const newSubscriptionDates = companies?.reduce(
        (acc: SubscriptionDates, company) => {
          acc[company.id] = company?.subscriptionDate
            ? new Date(company.subscriptionDate).toISOString().split("T")[0]
            : "";
          return acc;
        },
        {}
      );

      setSubscriptionDates(newSubscriptionDates);
    }
  }, [companies]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {companies?.map((company) => (
        <Box
          key={company.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text fontSize="xl">{company.name}</Text>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>Address: {company.address}</Text>
            <Text>Registration Number: {company.registrationNumber}</Text>
            <Text>Type: {company.type}</Text>
            <Text>Country: {company.country}</Text>
            <Text>Website url: {company.websiteUrl}</Text>
            <Text>
              User contact:{" "}
              {company.users[0]?.email + " " + company.users[0]?.phoneNumber}
            </Text>
            {company?.pricingPlan && (
              <>
                <Text>Plan name: {company?.pricingPlan?.name}</Text>
              </>
            )}
            {company?.pricingPlan && (
              <>
                <Text>Latest payment date:</Text>
                <Input
                  value={subscriptionDates?.[company.id] ?? ""}
                  onChange={(e) =>
                    handleSubscriptionDateChange(company.id, e.target.value)
                  }
                  type="date"
                  maxW="180px"
                />
              </>
            )}
            <Text>Credit limit:</Text>
            <InputGroup>
              <Input
                maxW={"120px"}
                defaultValue={company.creditInformation.creditLimit}
                onWheel={(e) => e.preventDefault()}
                onBlur={(e) => {
                  const rawValue = e.target.value.replace(/[^0-9.]/g, "");
                  const floatValue = rawValue
                    ? parseFloat(rawValue)
                    : company.creditInformation.creditLimit;
                  const formattedValue = formatCurrency(floatValue);

                  if (floatValue !== company.creditInformation.creditLimit) {
                    handleCreditLimitUpdate(company.id, floatValue);
                  }
                  // Update the input field to show the formatted number
                  e.target.value = formattedValue;
                }}
                type="string"
              />
            </InputGroup>
            <Text>Status: </Text>
            <select
              value={company.kycStatus}
              onChange={(e) => handleStatusChange(company.id, e.target.value)}
            >
              {Object.values(KycStatusEnum).map((status) => (
                <option key={status} value={status}>
                  {status.replace("_", " ")}
                </option>
              ))}
            </select>
            <div style={{ minHeight: "20px" }}>
              {errorMessages[company.id] && (
                <Text color="red.500">{errorMessages[company.id]}</Text>
              )}
            </div>
          </Box>

          {/* Files section */}
          <Box mb={4}>
            <Text mb={2}>KYC Documents:</Text>
            {company.files?.map((file, index) => (
              <Button
                fontSize="sm"
                color="grey"
                key={index}
                onClick={() => downloadFile(file.key, accessToken)}
              >
                {getFileText(file.type, index)}
              </Button>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { RecipientDto } from './recipient-dto';


export interface DirectPaymentDto { 
    id: string;
    country: string;
    amount: number;
    amountCurrency: DirectPaymentDto.AmountCurrencyEnum;
    exchangeRate: number;
    amountToPay: number;
    amountToPayCurrency: DirectPaymentDto.AmountToPayCurrencyEnum;
    recipientId: string;
    recipient: RecipientDto;
    status: DirectPaymentDto.StatusEnum;
    type: DirectPaymentDto.TypeEnum;
    termsAccepted: boolean;
    companyId: string;
    companyName: string;
    userId: string;
    paymentRequestId?: string;
    paymentRequestDueDate?: string;
    paymentRequestAmountToPayNow?: number;
    paymentRequestAmountToPayNowCurrency?: DirectPaymentDto.PaymentRequestAmountToPayNowCurrencyEnum;
    paymentRequestCreditAmount?: number;
    paymentRequestCreditAmountCurrency?: DirectPaymentDto.PaymentRequestCreditAmountCurrencyEnum;
    paymentRequestAmountToRepay?: number;
    paymentMethod: DirectPaymentDto.PaymentMethodEnum;
    paymentRequestAmountToRepayCurrency?: DirectPaymentDto.PaymentRequestAmountToRepayCurrencyEnum;
    files?: Array<FileDto>;
    createdAt: string;
    updatedAt: string;
}
export namespace DirectPaymentDto {
    export type AmountCurrencyEnum = 'USD' | 'NGN';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Ngn: 'NGN' as AmountCurrencyEnum
    };
    export type AmountToPayCurrencyEnum = 'USD' | 'NGN';
    export const AmountToPayCurrencyEnum = {
        Usd: 'USD' as AmountToPayCurrencyEnum,
        Ngn: 'NGN' as AmountToPayCurrencyEnum
    };
    export type StatusEnum = 'PENDING' | 'PROOF_OF_USER_PAYMENT_SUBMITTED' | 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' | 'DECLINED' | 'COMPLETED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        ProofOfUserPaymentSubmitted: 'PROOF_OF_USER_PAYMENT_SUBMITTED' as StatusEnum,
        ProofOfPlatformPaymentSubmitted: 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'PAY_NOW_SUPPLIER' | 'PAY_NOW_OTHER' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        PayNowSupplier: 'PAY_NOW_SUPPLIER' as TypeEnum,
        PayNowOther: 'PAY_NOW_OTHER' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
    export type PaymentRequestAmountToPayNowCurrencyEnum = 'USD' | 'NGN';
    export const PaymentRequestAmountToPayNowCurrencyEnum = {
        Usd: 'USD' as PaymentRequestAmountToPayNowCurrencyEnum,
        Ngn: 'NGN' as PaymentRequestAmountToPayNowCurrencyEnum
    };
    export type PaymentRequestCreditAmountCurrencyEnum = 'USD' | 'NGN';
    export const PaymentRequestCreditAmountCurrencyEnum = {
        Usd: 'USD' as PaymentRequestCreditAmountCurrencyEnum,
        Ngn: 'NGN' as PaymentRequestCreditAmountCurrencyEnum
    };
    export type PaymentMethodEnum = 'BANK_TRANSFER' | 'CASH_DEPOSIT' | 'FLEX_PAY';
    export const PaymentMethodEnum = {
        BankTransfer: 'BANK_TRANSFER' as PaymentMethodEnum,
        CashDeposit: 'CASH_DEPOSIT' as PaymentMethodEnum,
        FlexPay: 'FLEX_PAY' as PaymentMethodEnum
    };
    export type PaymentRequestAmountToRepayCurrencyEnum = 'USD' | 'NGN';
    export const PaymentRequestAmountToRepayCurrencyEnum = {
        Usd: 'USD' as PaymentRequestAmountToRepayCurrencyEnum,
        Ngn: 'NGN' as PaymentRequestAmountToRepayCurrencyEnum
    };
}



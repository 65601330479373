/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditInformationDto } from './credit-information-dto';
import { PricingPlanDto } from './pricing-plan-dto';


export interface CompanyDto { 
    id: string;
    name?: string | null;
    country: CompanyDto.CountryEnum;
    type: CompanyDto.TypeEnum;
    address?: string | null;
    kycStatus?: CompanyDto.KycStatusEnum;
    registrationNumber?: string | null;
    vatNumber?: string | null;
    websiteUrl?: string | null;
    yearsInBusiness?: number | null;
    employeeCount?: number | null;
    customerCount?: number | null;
    estimatedMonthlyRevenue?: number | null;
    creditInformation?: CreditInformationDto | null;
    feePercentage?: number | null;
    pricingPlan?: PricingPlanDto | null;
    subscriptionDate?: string | null;
    trialExpiresAt?: string;
}
export namespace CompanyDto {
    export type CountryEnum = 'Nigeria';
    export const CountryEnum = {
        Nigeria: 'Nigeria' as CountryEnum
    };
    export type TypeEnum = 'Business' | 'Individual' | 'NotSpecified';
    export const TypeEnum = {
        Business: 'Business' as TypeEnum,
        Individual: 'Individual' as TypeEnum,
        NotSpecified: 'NotSpecified' as TypeEnum
    };
    export type KycStatusEnum = 'NOT_SUBMITTED' | 'PENDING' | 'APPROVED' | 'DECLINED';
    export const KycStatusEnum = {
        NotSubmitted: 'NOT_SUBMITTED' as KycStatusEnum,
        Pending: 'PENDING' as KycStatusEnum,
        Approved: 'APPROVED' as KycStatusEnum,
        Declined: 'DECLINED' as KycStatusEnum
    };
}



import { Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Pagination from "../sharedComponents/pagination";
import { useFetchProducts } from "./hooks/FetchProducts";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import AddProductModal from "./components/AddProductModal";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteProduct } from "services/product";
import EditProductModal from "./components/EditProductModal";
import { ProductDto } from "generated-client/model/product-dto";

export default function Products() {
  const { getAccessTokenSilently } = useAuth0();
  const { products, fetchData } = useFetchProducts();
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [product, setProduct] = useState<ProductDto>();
  const [refresh, setRefresh] = useState(false);

  const pageSize = 20;
  const isTableLayout = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    fetchData({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, refresh]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDeleteProduct = async (productId: string) => {
    const accessToken = await getAccessTokenSilently();
    await deleteProduct(accessToken, productId);
    fetchData({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
    });
  };

  const handleOpenEditProduct = (product: ProductDto, status: boolean) => {
    setProduct(product);
    setIsEditProductModalOpen(status);
  };

  return (
    <>
      <Flex pt={{ base: "50px", md: "30px", xl: "30px" }}>
        <Button
          colorScheme="blue"
          onClick={() => setIsNewProductModalOpen(true)}
        >
          Add product
        </Button>
      </Flex>

      {isTableLayout ? (
        <TableComponent
          products={products}
          handleDeleteProduct={handleDeleteProduct}
          handleOpenEditProduct={handleOpenEditProduct}
        />
      ) : (
        <CardLayout
          products={products}
          handleDeleteProduct={handleDeleteProduct}
          handleOpenEditProduct={handleOpenEditProduct}
        />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={products.length}
        onPageChange={handlePageChange}
      />

      <AddProductModal
        isOpen={isNewProductModalOpen}
        onClose={() => {
          setIsNewProductModalOpen(false);
        }}
        updateRefreshStatus={setRefresh}
      />

      {isEditProductModalOpen && (
        <EditProductModal
          isOpen={isEditProductModalOpen}
          onClose={() => {
            setIsEditProductModalOpen(false);
          }}
          updateRefreshStatus={setRefresh}
          id={product.id}
          name={product.name}
          price={product.price}
        />
      )}
    </>
  );
}

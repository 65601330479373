import {
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
  Text,
  Box,
  Flex,
  Select,
  VStack,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getCompany,
  setKycDocumentsSubmitted,
  updateCompany,
} from "../../../services/company";
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import { uploadFile } from "../../../services/file";
import KycStatusEnum = CompanyDto.KycStatusEnum;
import { FileDto } from "../../../generated-client/model/file-dto";

const documentsRequiredByCountry: Record<string, string[]> = {
  Kenya_Business: [
    "Certificate of Incorporation",
    "Company Tax Identification (PIN Certificate)",
    "Register of Shareholders (CR12)",
    "Business permit",
    "Directors Photo – ID ( ID, Passport, Driving License)",
    "Shareholders (> 10%) Photo – ID ( ID, Passport, Driving License)",
    "Directors and Shareholders (>10%) Personal PIN Certificates",
  ],
  Kenya_Individual: ["Government ID", "PIN Certificate"],
  Rwanda_Business: [
    "RDB - Full Registration Information of Domestic Company (with shareholder information)",
    "Directors Photo – ID ( ID, Passport, Driving License)",
    "Shareholders (> 25%) Photo – ID ( ID, Passport, Driving License)",
  ],
  Rwanda_Individual: [
    "Copy of passport/driving license",
    "Proof of Address: Utility bill/bank statement showing both name and address, dated within the last three months",
  ],
};

const getDocsConfig = (country: string, companyType: string) => {
  const docsByCountryAndCompanyType =
    documentsRequiredByCountry?.[`${country}_${companyType}`];
  return (
    docsByCountryAndCompanyType?.map((docName) => ({
      name: docName,
      isRequired: true,
    })) || []
  );
};

export default function Kyc() {
  const SAVE_STATES = {
    IDLE: "idle",
    SAVING: "saving",
    SUCCESS: "success",
    ERROR: "error",
  };
  const [saveState, setSaveState] = useState(SAVE_STATES.IDLE);
  const [companyData, setCompanyData] = useState<CompanyDto>();
  const [companyType, setCompanyType] = useState<CompanyDto.TypeEnum>();
  const [, setWebsiteUrl] = useState("");
  const [country, setCountry] = useState<CompanyDto.CountryEnum>("Nigeria");
  const [validationError, setValidationError] = useState("");
  const [files, setFiles] = useState<any>({});
  const navigate = useNavigate();

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const handleFileChange = (e: any, docType: string) => {
    const newFiles = [...(files[docType] || []), ...Array.from(e.target.files)];
    setFiles((prev: any) => ({ ...prev, [docType]: newFiles }));
  };

  const triggerFileInput = (docType: string) => {
    document.getElementById(docType).click();
  };

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getCompany(accessToken);
      setCompanyData(data.company);
      setWebsiteUrl(data.company.websiteUrl);
      setCountry(data.company.country);
    } catch (error) {}
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  async function handleSave() {
    try {
      const requiredDocuments =
        documentsRequiredByCountry[`${country}_${companyType}`] || [];
      const uploadedDocuments = Object.keys(files);

      // Check if all required documents are uploaded, excluding the bank statement
      const missingDocuments = requiredDocuments.filter(
        (docType: string) => !uploadedDocuments.includes(docType)
      );

      if (missingDocuments.length > 0) {
        setValidationError("All required documents must be uploaded.");
        return;
      } else {
        setValidationError("");
      }

      setSaveState(SAVE_STATES.SAVING);
      const accessToken = await getAccessTokenSilently();
      // Upload files one by one
      var index = 0;
      for (const fileArray of Object.values(files)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const key = Object.keys(files)[index];
        for (const file of fileArray as any[]) {
          if (file) {
            // Ensure file is not null or undefined
            await uploadFile(
              accessToken,
              {
                type: FileDto.TypeEnum.KycDocument,
              },
              file as File
            );
          }
        }
        index++;
      }

      await updateCompany(accessToken, {
        type: companyType,
      });

      await setKycDocumentsSubmitted(accessToken);

      setSaveState(SAVE_STATES.SUCCESS);

      setTimeout(() => {
        navigate("/dashboard/usdPayments");
      }, 1000);

      // Reset to the default state after 2 seconds
      setTimeout(() => {
        setSaveState(SAVE_STATES.IDLE);
      }, 2000);
    } catch (error) {
      setSaveState(SAVE_STATES.ERROR);
      setTimeout(() => {
        setSaveState(SAVE_STATES.IDLE);
      }, 2000);
    }
  }

  if (!companyData) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  // Check if the kycStatus is PENDING
  if (companyData.kycStatus !== KycStatusEnum.NotSubmitted) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          w={{ base: "100%", md: "50%" }}
          mb={{ base: "0px", "2xl": "20px" }}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          pe="20px"
        >
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {"Document Review"}
          </Text>
          {companyData.kycStatus === KycStatusEnum.Pending && (
            <Text mb={4}>
              {
                "Your documents are being reviewed. We will notify you when your account has been activated."
              }
            </Text>
          )}
          {companyData.kycStatus === KycStatusEnum.Declined && (
            <Text mb={4}>
              {
                "Your documents have been declined. Please contact us for more details."
              }
            </Text>
          )}
          {companyData.kycStatus === KycStatusEnum.Approved && (
            <Text mb={4}>
              {"Your documents have been approved. You can now proceed."}
            </Text>
          )}
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "50%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="365px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {"Company and ID verification"}
        </Text>

        <Text mb={4}>{"Select your type of business"}</Text>
        <FormControl pt="15px" isRequired>
          <Select
            placeholder="Select"
            onChange={(e: any) =>
              setCompanyType(e.target.value as CompanyDto.TypeEnum)
            }
            defaultValue=""
            maxW="250px"
          >
            <option value="Business">Private Limited Company</option>
            <option value="Individual">Sole trader/individual </option>
          </Select>
        </FormControl>

        {companyType && (
          <>
            <Box mt={4} />
            <Text mb={4}>
              {"Upload the following documents to start transacting"}
            </Text>
          </>
        )}

        <Box mt={4} />

        <SimpleGrid columns={1} spacing={4}>
          {getDocsConfig(country, companyType)?.map(
            ({ name: docType, isRequired }, index: number) => (
              <FormControl key={index} isRequired={isRequired}>
                <FormLabel>{docType}</FormLabel>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id={docType}
                  onChange={(e) => handleFileChange(e, docType)}
                  accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                />
                <Flex alignItems="center">
                  {files[docType] && files[docType].length > 0 ? (
                    <VStack align="start" spacing={1}>
                      {files[docType].map((file: any, fileIndex: any) => (
                        <Text key={fileIndex} fontSize="sm">
                          {file.name}
                        </Text>
                      ))}
                    </VStack>
                  ) : null}
                  <Button
                    onClick={() => triggerFileInput(docType)}
                    ml={3}
                    style={{ backgroundColor: "#e1e1e1" }}
                  >
                    <Text fontSize="sm" color="grey">
                      Choose file
                    </Text>
                  </Button>
                </Flex>
              </FormControl>
            )
          )}
        </SimpleGrid>

        {companyType && (
          <Flex
            justifyContent="flex-end"
            mt={4}
            flexDirection="column"
            alignItems="flex-end"
          >
            {validationError && (
              <Text color="red.500" mb={2}>
                {validationError}
              </Text>
            )}
            <Button
              colorScheme={saveState === SAVE_STATES.SUCCESS ? "green" : "blue"}
              onClick={handleSave}
              isLoading={saveState === SAVE_STATES.SAVING}
              rightIcon={
                saveState === SAVE_STATES.SUCCESS ? <CheckIcon /> : null
              }
            >
              {saveState === SAVE_STATES.SUCCESS ? "Submitted" : "Submit"}
            </Button>
          </Flex>
        )}
      </Card>
    </Box>
  );
}

/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemDto } from './item-dto';


export interface CreatePaymentRequestRequest { 
    amount: number;
    amountCredit: number;
    amountCurrency: CreatePaymentRequestRequest.AmountCurrencyEnum;
    amountInSecondaryCurrency: number;
    amountInSecondaryCurrencyCurrency: CreatePaymentRequestRequest.AmountInSecondaryCurrencyCurrencyEnum;
    type: CreatePaymentRequestRequest.TypeEnum;
    paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum;
    durationInDays: number;
    termsAccepted: boolean;
    supplierName: string;
    supplierId: string;
    items: Array<ItemDto>;
}
export namespace CreatePaymentRequestRequest {
    export type AmountCurrencyEnum = 'USD' | 'NGN';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Ngn: 'NGN' as AmountCurrencyEnum
    };
    export type AmountInSecondaryCurrencyCurrencyEnum = 'USD' | 'NGN';
    export const AmountInSecondaryCurrencyCurrencyEnum = {
        Usd: 'USD' as AmountInSecondaryCurrencyCurrencyEnum,
        Ngn: 'NGN' as AmountInSecondaryCurrencyCurrencyEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
    export type PaymentMethodEnum = 'TRANSFER' | 'ON_DELIVERY' | 'CREDIT' | 'NOT_AVAILABLE';
    export const PaymentMethodEnum = {
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        OnDelivery: 'ON_DELIVERY' as PaymentMethodEnum,
        Credit: 'CREDIT' as PaymentMethodEnum,
        NotAvailable: 'NOT_AVAILABLE' as PaymentMethodEnum
    };
}



import {
  Box,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { PaymentFlow } from "./components/paymentFlow";
import { useAuth0 } from "@auth0/auth0-react";
import { getDirectPayments } from "services/direct-payment";
import { DirectPaymentDto } from "generated-client/model/direct-payment-dto";

interface Invoice {
  date: string;
  supplier: string;
  invoiceId: string;
  amount: string;
  status: string;
  dueDate: string;
}

const Pay = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [payments, setPayments] = useState<DirectPaymentDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchPayments = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        const data = await getDirectPayments(accessToken);
        setPayments(data.directPayments);
      } catch (error) {
        console.error("Failed to fetch payments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments();
  }, [getAccessTokenSilently]);

  const invoices: Invoice[] = [
    {
      date: "Nov 5",
      supplier: "Dubai Exporters",
      invoiceId: "INV-001",
      amount: "$150,000",
      status: "Pending",
      dueDate: "Dec 5",
    },
    {
      date: "Nov 3",
      supplier: "China Exports",
      invoiceId: "INV-002",
      amount: "$80,000",
      status: "Paid",
      dueDate: "Dec 3",
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });

  const getStatusColor = (status: string) => {
    switch (status) {
      case DirectPaymentDto.StatusEnum.Completed:
        return "#005a32";
      case DirectPaymentDto.StatusEnum.Pending:
      case DirectPaymentDto.StatusEnum.ProofOfUserPaymentSubmitted:
      case DirectPaymentDto.StatusEnum.ProofOfPlatformPaymentSubmitted:
        return "#f29339";
      case DirectPaymentDto.StatusEnum.Declined:
        return "gray.500";
      default:
        return "gray.500";
    }
  };

  const selectedTabStyle = {
    color: "black",
    borderBottom: "3px solid",
    borderColor: "#414BB2",
    mb: "-1px",
  };

  const tableStyles = {
    "& tr td, & tr th": {
      borderBottom: "1px solid",
      borderColor: "#ececec",
      color: "#1a1a1a",
    },
    "& thead th": {
      color: "#8a8a8a",
      textTransform: "none",
    },
  };

  const handlePaymentComplete = () => {
    // Refresh the payments list
    const fetchPayments = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        const data = await getDirectPayments(accessToken);
        setPayments(data.directPayments);
      } catch (error) {
        console.error("Failed to fetch payments:", error);
      }
    };

    fetchPayments();
  };

  const hasProofOfPayment = (payment: DirectPaymentDto) => {
    return payment.files?.some((file) => file.type === "PROOF_OF_USER_PAYMENT");
  };

  // const hasInvoice = (payment: DirectPaymentDto) => {
  //   return payment.files?.some((file) => file.type === "INVOICE");
  // };

  // Add this helper function to generate the S3 URL
  const getFileUrl = (fileKey: string) => {
    return `https://uploaded-files-yolu.s3.eu-west-2.amazonaws.com/${fileKey}`;
  };

  // Add these helper functions to find specific files
  const getProofOfPaymentFile = (payment: DirectPaymentDto) => {
    return payment.files?.find((file) => file.type === "PROOF_OF_USER_PAYMENT");
  };

  // const getInvoiceFile = (payment: DirectPaymentDto) => {
  //   return payment.files?.find((file) => file.type === "INVOICE");
  // };

  // Add this helper function to format payment method
  const formatPaymentMethod = (method: string) => {
    return method
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Add this helper function to format amount with currency
  const formatAmountWithCurrency = (amount: number, currency: string) => {
    const formattedAmount = amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return currency === "USD" ? `$${formattedAmount}` : `₦${formattedAmount}`;
  };

  return (
    <Box bg="white" minH="100vh">
      <Box p={4} maxW="1400px" mx="auto" pt={16}>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align={{ base: "stretch", md: "center" }}
          mb={8}
        >
          <Text fontSize="2xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
            Pay
          </Text>

          <Flex gap={3} direction={{ base: "column", sm: "row" }}>
            {/* Comment out Add invoice button
            <Button
              variant="outline"
              borderRadius="md"
              fontSize="sm"
              h="38px"
              borderColor="#1a1a1a"
              _hover={{ borderColor: "#1a1a1a" }}
              leftIcon={
                <Text as="span" fontSize="lg" mr={1}>
                  +
                </Text>
              }
            >
              Add invoice
            </Button>
            */}
            <Button
              bg="black"
              color="white"
              borderRadius="md"
              fontSize="sm"
              _hover={{ bg: "gray.800" }}
              leftIcon={
                <Text as="span" fontSize="lg" mr={1}>
                  +
                </Text>
              }
              h="38px"
              onClick={() => setIsPaymentModalOpen(true)}
            >
              New payment
            </Button>
          </Flex>
        </Flex>

        <Tabs index={tabIndex} onChange={setTabIndex}>
          <TabList borderBottom="1px" borderColor="gray.200" mb={8}>
            <Tab
              px={0}
              mr={8}
              fontWeight="bold"
              fontSize="lg"
              color="gray.500"
              _selected={selectedTabStyle}
            >
              Payments
            </Tab>
            <Tab
              px={0}
              fontWeight="bold"
              fontSize="lg"
              color="gray.500"
              _selected={selectedTabStyle}
              cursor="not-allowed"
              isDisabled={true}
              opacity={0.5}
            >
              Invoices
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {isLoading ? (
                <Flex justify="center" align="center" minH="200px">
                  <Text>Loading payments...</Text>
                </Flex>
              ) : !isMobile ? (
                <TableContainer>
                  <Table variant="unstyled" sx={tableStyles}>
                    <Thead>
                      <Tr>
                        <Th pl={0} fontWeight="medium" fontSize="md">
                          Date
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Supplier
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Payment method
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Amount
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Status
                        </Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {payments?.map((payment, index) => (
                        <Tr key={index}>
                          <Td pl={0} fontWeight="normal">
                            {new Date(payment.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </Td>
                          <Td fontWeight="medium" fontSize="md">
                            {payment.recipient?.name}
                          </Td>
                          <Td fontWeight="medium" fontSize="md">
                            {formatPaymentMethod(payment.paymentMethod)}
                          </Td>
                          <Td fontWeight="medium" fontSize="md">
                            {formatAmountWithCurrency(
                              payment.amount,
                              payment.amountCurrency
                            )}
                          </Td>
                          <Td fontWeight="medium" fontSize="md">
                            <Text
                              color={getStatusColor(payment.status)}
                              textTransform="uppercase"
                            >
                              {payment.status ===
                              DirectPaymentDto.StatusEnum.Completed
                                ? "Paid"
                                : payment.status ===
                                  DirectPaymentDto.StatusEnum
                                    .ProofOfUserPaymentSubmitted
                                ? "Pending"
                                : payment.status}
                            </Text>
                          </Td>
                          <Td>
                            <Flex gap={2}>
                              {hasProofOfPayment(payment) && (
                                <Button
                                  variant="outline"
                                  size="sm"
                                  borderRadius="md"
                                  borderColor="#1a1a1a"
                                  _hover={{ borderColor: "#1a1a1a" }}
                                  h="32px"
                                  as="a"
                                  href={getFileUrl(
                                    getProofOfPaymentFile(payment)?.key || ""
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Proof of payment
                                </Button>
                              )}
                              {/* {hasInvoice(payment) && (
                                <Button
                                  variant="outline"
                                  size="sm"
                                  borderRadius="md"
                                  borderColor="#1a1a1a"
                                  _hover={{ borderColor: "#1a1a1a" }}
                                  h="32px"
                                  as="a"
                                  href={getFileUrl(
                                    getInvoiceFile(payment)?.key || ""
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Invoice
                                </Button>
                              )} */}
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Box mt={4}>
                  {payments?.map((payment, index) => (
                    <Box
                      key={index}
                      p={4}
                      mb={4}
                      borderWidth="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                    >
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Date
                        </Text>
                        <Text fontWeight="medium">
                          {new Date(payment.createdAt).toLocaleDateString(
                            "en-GB"
                          )}
                        </Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Supplier
                        </Text>
                        <Text fontWeight="medium">
                          {payment.recipient?.name}
                        </Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Payment method
                        </Text>
                        <Text fontWeight="medium">
                          {formatPaymentMethod(payment.paymentMethod)}
                        </Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Amount
                        </Text>
                        <Text fontWeight="medium">
                          {formatAmountWithCurrency(
                            payment.amount,
                            payment.amountCurrency
                          )}
                        </Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Status
                        </Text>
                        <Text color={getStatusColor(payment.status)}>
                          {payment.status ===
                          DirectPaymentDto.StatusEnum.Completed
                            ? "Paid"
                            : payment.status ===
                              DirectPaymentDto.StatusEnum
                                .ProofOfUserPaymentSubmitted
                            ? "Pending"
                            : payment.status}
                        </Text>
                      </Flex>
                      {/* {(hasProofOfPayment(payment) || hasInvoice(payment)) && ( */}
                      {hasProofOfPayment(payment) && (
                        <Flex gap={2} mt={2}>
                          {hasProofOfPayment(payment) && (
                            <Button
                              variant="outline"
                              size="sm"
                              flex={1}
                              borderColor="gray.200"
                              as="a"
                              href={getFileUrl(
                                getProofOfPaymentFile(payment)?.key || ""
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Proof of payment
                            </Button>
                          )}
                          {/* {hasInvoice(payment) && (
                            <Button
                              variant="outline"
                              size="sm"
                              flex={1}
                              borderColor="gray.200"
                              as="a"
                              href={getFileUrl(
                                getInvoiceFile(payment)?.key || ""
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Invoice
                            </Button>
                          )} */}
                        </Flex>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </TabPanel>

            <TabPanel px={0}>
              {!isMobile ? (
                <TableContainer>
                  <Table variant="unstyled" sx={tableStyles}>
                    <Thead>
                      <Tr>
                        <Th pl={0} fontWeight="medium" fontSize="md">
                          Date
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Supplier
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Invoice ID
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Amount
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Due Date
                        </Th>
                        <Th fontWeight="medium" fontSize="md">
                          Status
                        </Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {invoices.map((invoice, index) => (
                        <Tr key={index}>
                          <Td pl={0} fontWeight="medium">
                            {invoice.date}
                          </Td>
                          <Td fontWeight="medium">{invoice.supplier}</Td>
                          <Td fontWeight="medium">{invoice.invoiceId}</Td>
                          <Td fontWeight="medium">{invoice.amount}</Td>
                          <Td fontWeight="medium">{invoice.dueDate}</Td>
                          <Td fontWeight="medium">
                            <Text color={getStatusColor(invoice.status)}>
                              {invoice.status}
                            </Text>
                          </Td>
                          <Td>
                            <Button
                              variant="outline"
                              size="sm"
                              borderRadius="md"
                              borderColor="gray.200"
                              h="32px"
                            >
                              View invoice
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Box mt={4}>
                  {invoices.map((invoice, index) => (
                    <Box
                      key={index}
                      p={4}
                      mb={4}
                      borderWidth="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                    >
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Date:
                        </Text>
                        <Text fontWeight="medium">{invoice.date}</Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Supplier:
                        </Text>
                        <Text fontWeight="medium">{invoice.supplier}</Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Invoice:
                        </Text>
                        <Text fontWeight="medium">{invoice.invoiceId}</Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Amount:
                        </Text>
                        <Text fontWeight="medium">{invoice.amount}</Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Due:
                        </Text>
                        <Text fontWeight="medium">{invoice.dueDate}</Text>
                      </Flex>
                      <Flex justify="space-between" mb={2}>
                        <Text
                          fontWeight="medium"
                          fontSize="sm"
                          color="gray.600"
                        >
                          Status:
                        </Text>
                        <Text color={getStatusColor(invoice.status)}>
                          {invoice.status}
                        </Text>
                      </Flex>
                      <Button
                        variant="outline"
                        size="sm"
                        width="100%"
                        mt={2}
                        borderColor="gray.200"
                      >
                        View invoice
                      </Button>
                    </Box>
                  ))}
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <PaymentFlow
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          onPaymentComplete={handlePaymentComplete}
        />
      </Box>
    </Box>
  );
};

export default Pay;

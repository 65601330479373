import { Flex, Input, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import { DirectPaymentDto } from "../../../generated-client/model/direct-payment-dto";
import {
  attachProofOfUserPayment,
  updateDirectPaymentAdmin,
} from "../../../services/direct-payment";
import { uploadFile } from "services/file";
import { useFetchDirectPayments } from "./hooks/FetchDirectPayments";
import Pagination from "../sharedComponents/pagination";

export default function AllDirectPayments() {
  const { directPayments, fetchData, accessToken } = useFetchDirectPayments();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  useAuth0();
  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const [errorMessages] = useState<{ [requestId: string]: string }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const filteredDirectPayments = directPayments.filter((request) => {
    return request?.companyName
      ?.toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());
  });

  async function sendFile(file: File, directPaymentId: string) {
    try {
      const fileUploaded = await uploadFile(
        accessToken,
        {
          directPaymentId: directPaymentId,
          type: "PROOF_OF_PLATFORM_PAYMENT",
        },
        file
      );

      await attachProofOfUserPayment(accessToken, {
        id: directPaymentId,
        fileId: fileUploaded.id,
      });
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  async function handleStatusChange(
    directPaymentId: string,
    newStatus: string
  ) {
    try {
      await updateDirectPaymentAdmin(accessToken, {
        id: directPaymentId,
        status: newStatus as DirectPaymentDto.StatusEnum,
      });

      setTimeout(() => {
        fetchData((currentPage - 1) * pageSize, pageSize, debouncedSearchTerm);
      }, 1500);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetchData((currentPage - 1) * pageSize, pageSize, debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchTerm]);

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {" "}
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by company name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
      </Flex>
      {isTableLayout ? (
        <TableComponent
          directPayments={filteredDirectPayments}
          accessToken={accessToken}
          sendFile={sendFile}
          fetchData={fetchData}
          handleStatusChange={handleStatusChange}
          errorMessages={errorMessages}
        />
      ) : (
        <CardLayout
          directPayments={filteredDirectPayments}
          accessToken={accessToken}
          sendFile={sendFile}
          fetchData={fetchData}
          handleStatusChange={handleStatusChange}
          errorMessages={errorMessages}
        />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={filteredDirectPayments.length}
        onPageChange={handlePageChange}
      />
    </>
  );
}

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdPayments,
  MdSquare,
  MdArrowUpward,
  MdShoppingCart,
  MdAttachMoney,
  MdOutlineCurrencyExchange,
} from "react-icons/md";

import Profile from "views/dashboard/profile";
import SignInCentered from "views/auth/signIn";
import AllPaymentRequests from "./views/admin/default";
import RepaymentAccountDetails from "./views/dashboard/repaymentAccountDetails";
import Kyc from "./views/dashboard/kyc";
import Companies from "./views/admin/companies";
import Finance from "./views/admin/finance";
import HelpCenter from "./views/dashboard/helpCenter";
import Products from "views/admin/products";
import Shop from "views/dashboard/shop";
import PaymentPage from "views/dashboard/shop/payment";
import AllDirectPayments from "views/admin/directPayments";
import Pay from "views/dashboard/pay";

// Helper function to create aligned icon
const createIcon = (IconComponent: any) => (
  <Icon
    as={IconComponent}
    width="20px"
    height="20px"
    color="inherit"
    display="flex"
    alignItems="center"
  />
);

const routes = [
  {
    name: "Company details",
    layout: "/dashboard",
    path: "/profile",
    icon: createIcon(MdPerson),
    component: Profile,
    hidden: true,
  },
  {
    name: "Company documents",
    layout: "/dashboard",
    path: "/kyc",
    icon: createIcon(MdPerson),
    component: Kyc,
    hidden: true,
  },
  {
    name: "Repayment details",
    layout: "/dashboard",
    path: "/repaymentAccountDetails",
    icon: createIcon(MdPerson),
    component: RepaymentAccountDetails,
    hidden: true,
  },
  {
    name: "Help Center",
    layout: "/dashboard",
    path: "/helpCenter",
    icon: createIcon(MdPerson),
    component: HelpCenter,
    hidden: true,
  },

  {
    name: "Payment",
    layout: "/dashboard",
    path: "/payment",
    icon: createIcon(MdAttachMoney),
    component: PaymentPage,
    hidden: true,
  },
  {
    name: "Pay",
    layout: "/dashboard",
    path: "/default",
    icon: createIcon(MdAttachMoney),
    component: Pay,
    hidden: false,
  },
  {
    name: "Shop",
    layout: "/dashboard",
    path: "/shop",
    icon: createIcon(MdShoppingCart),
    component: Shop,
    hidden: false,
  },
  {
    name: "Log in",
    layout: "/auth",
    path: "/sign-in",
    icon: createIcon(MdPerson),
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Flex pay transactions",
    layout: "/admin",
    path: "/default",
    icon: createIcon(MdPayments),
    component: AllPaymentRequests,
    hidden: false,
    admin: true,
  },
  {
    name: "International",
    layout: "/admin",
    path: "/directPayments",
    icon: (
      <Icon
        as={MdOutlineCurrencyExchange}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: AllDirectPayments,
    hidden: false,
    admin: true,
  },
  {
    name: "Companies",
    layout: "/admin",
    path: "/companies",
    icon: createIcon(MdSquare),
    component: Companies,
    hidden: false,
    admin: true,
  },
  {
    name: "Exchange rates",
    layout: "/admin",
    path: "/exchangeRates",
    icon: createIcon(MdArrowUpward),
    component: Finance,
    hidden: false,
    admin: true,
  },
  {
    name: "Featured products",
    layout: "/admin",
    path: "/products",
    icon: createIcon(MdSquare),
    component: Products,
    hidden: false,
    admin: true,
  },
];

export default routes;

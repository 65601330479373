/* eslint-disable */
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import DashboardNavbarLinks from "components/navbar/NavbarLinksDashboard";
import { CompanyDto } from "../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

export default function DashboardNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  userAdmin: boolean;
  companyCountry: CountryEnum;
  onOpen: (...args: any[]) => any;
}) {
  const [, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, userAdmin } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let navbarPosition = "fixed" as const;
  let navbarFilter = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition} // Make sure this is set to either 'absolute' or 'fixed'
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration="0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "flex-end" }} // Aligns children to the right
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }} // Adjust as needed
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w="auto" // Set width to auto or a specific value
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        justifyContent="flex-end"
        mb={gap}
      >
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <DashboardNavbarLinks
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
            userAdmin={userAdmin}
            companyCountry={props.companyCountry}
          />
        </Box>
      </Flex>
    </Box>
  );
}

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { ProductDto } from "generated-client/model/product-dto";
import { getAllProductsAdmin } from "services/product";

export const useFetchProducts = () => {
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async ({
    skip = 0,
    take = 20,
  }: {
    skip?: number;
    take?: number;
  }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllProductsAdmin(accessToken, {
        skip,
        take,
      });
      setProducts(data.products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { products, accessToken, fetchData };
};

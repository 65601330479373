import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Input,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { differenceInCalendarDays, addDays, subDays } from "date-fns";

import { PaymentRequestDto } from "../../../generated-client/model/payment-request-dto";
import {
  downloadPurchaseOrder,
  updatePaymentRequestAdmin,
  updatePaymentRequestInstalmentAdmin,
} from "../../../services/payment-request";
import { PaymentRequestDtoWithOtherData } from "../../../generated-client/model/payment-request-dto-with-other-data";
import StatusEnum = PaymentRequestDto.StatusEnum;
import RequestModal from "./components/RequestModal";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import { useFetchPaymentRequests } from "./hooks/FetchPaymentRequests";
import Pagination from "../sharedComponents/pagination";
import { downloadFile } from "../../../services/file";
import ConfirmDialog from "./components/ConfirmDialog";
import { formatCurrency } from "lib/utilities";

export default function AllPaymentRequests() {
  const { paymentRequests, fetchData, accessToken } = useFetchPaymentRequests();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [isModalOpen, setModalOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedRequest, setSelectedRequest] =
    useState<PaymentRequestDtoWithOtherData | null>(null);
  const [errorMessages, setErrorMessages] = useState<{
    [requestId: string]: string;
  }>({});
  const [isSearching, setIsSearching] = useState(false);
  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMinAmount, setSearchMinAmount] = useState(0);
  const [searchMaxAmount, setSearchMaxAmount] = useState(100000000);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingRequest, setPendingRequest] = useState(null);
  const [pendingStatus, setPendingStatus] = useState("");
  const [confirmationMessageType, setConfirmationMessageType] = useState("");
  const [confirmationData, setConfirmationData] = useState(null);

  const handleSearch = async () => {
    setIsSearching(true);
    await fetchData(
      0, // Reset to the first page
      pageSize,
      searchMinAmount,
      searchMaxAmount,
      searchTerm
    );
    setIsSearching(false);
    setCurrentPage(1);
  };

  const downloadFileForRequest = async (id: string, requestId: string) => {
    setRowLoading(requestId, true);
    await downloadFile(id, accessToken);
    setRowLoading(requestId, false);
  };

  const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleStatusChange = async (
    request: PaymentRequestDtoWithOtherData,
    newStatus: string
  ) => {
    const givenDate = new Date(request.createdAt);
    const today = new Date();
    const isSameDay: boolean = isSameDate(givenDate, today);

    // Check if the dueDate is today or in the future
    const dueDate = new Date(request.dueDate);
    if (dueDate >= today && newStatus === StatusEnum.RepaidLate) {
      // Do nothing if the dueDate is today or in the future
      return;
    } else if (newStatus === StatusEnum.RepaidLate) {
      const today = new Date();

      // 1. Set late repayment date
      const lateRepaymentDate = today;

      // 2. Calculate days late
      const dueDate = new Date(request.dueDate);
      const dayAfterDueDate = addDays(dueDate, 1); // Increment by one day

      // Subtract one day from the late repayment date to exclude the repaid date
      const dayBeforeRepaymentDate = subDays(lateRepaymentDate, 1);

      // Calculate the difference in days
      const daysLate = Math.max(
        0,
        differenceInCalendarDays(dayBeforeRepaymentDate, dayAfterDueDate) + 1 // Add 1 to include the last day
      );

      const lateFeePercentage = 0.001; // 0.1%
      const lateFee = Math.round(
        request.amountCredit * daysLate * lateFeePercentage
      );

      console.log({ lateFee, daysLate });

      setPendingRequest(request);
      setPendingStatus(newStatus);

      setConfirmationMessageType("LateFee");
      setConfirmationData({ lateFee });
      onOpen();
    } else if (newStatus === StatusEnum.PaymentSubmitted && !isSameDay) {
      const currentDate = new Date();

      const newDueDate = new Date(
        currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
      );

      setPendingRequest(request);
      setPendingStatus(newStatus);

      setConfirmationMessageType("PaymentSubmitted");
      setConfirmationData({ newDueDate });
      onOpen();
    } else {
      await updateRequestStatus(request, newStatus);
    }
  };

  const updateRequestStatus = async (
    request: PaymentRequestDtoWithOtherData,
    newStatus: string
  ) => {
    const newErrorMessages = { ...errorMessages };

    setErrorMessages(newErrorMessages);

    if (!newErrorMessages[request.id]) {
      const response = await updatePaymentRequestAdmin(accessToken, {
        id: request.id,
        status: newStatus as StatusEnum,
      });

      // @ts-ignore
      if (response.success) {
        await fetchData(
          (currentPage - 1) * pageSize,
          pageSize,
          searchMinAmount,
          searchMaxAmount,
          searchTerm
        );
        onClose();
      }
    }
  };

  useEffect(() => {
    // adding event listener
    window.addEventListener("keydown", handleKeyDown);
    // clean up
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleInstalmentStatusChange = async (
    paymentRequestId: string,
    id: string,
    newStatus: string
  ) => {
    await updatePaymentRequestInstalmentAdmin(accessToken, {
      paymentRequestId,
      id,
      status: newStatus as StatusEnum,
    });

    setTimeout(() => {
      fetchData(
        (currentPage - 1) * pageSize,
        pageSize,
        searchMinAmount,
        searchMaxAmount,
        searchTerm
      );
    }, 500);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleOpenModal = (request: PaymentRequestDtoWithOtherData) => {
    setSelectedRequest(request);
    setModalOpen(true);
  };

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };

  const handlePurchaseOrderDownload = async (
    request: PaymentRequestDtoWithOtherData
  ) => {
    setRowLoading(request.id, true);
    await downloadPurchaseOrder(request.id, accessToken);
    setRowLoading(request.id, false);
  };

  const handleCloseModal = () => {
    setSelectedRequest(null);
    setModalOpen(false);
  };

  useEffect(() => {
    fetchData(
      (currentPage - 1) * pageSize,
      pageSize,
      searchMinAmount,
      searchMaxAmount,
      searchTerm
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchMaxAmount, searchMinAmount, searchTerm]);

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by company name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Input
          placeholder="Amount from"
          onChange={(e) => {
            setSearchMinAmount(parseInt(e.target.value));
          }}
          type="number"
          value={searchMinAmount}
          w="150px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Input
          placeholder="Amount to"
          onChange={(e) => setSearchMaxAmount(parseInt(e.target.value))}
          type="number"
          value={searchMaxAmount}
          w="150px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Button
          onClick={handleSearch}
          onKeyDown={handleKeyDown}
          isLoading={isSearching}
        >
          Search
        </Button>
      </Flex>
      {isTableLayout ? (
        <TableComponent
          paymentRequests={paymentRequests}
          loadingStates={loadingStates}
          downloadFile={downloadFileForRequest}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
          handleStatusChange={handleStatusChange}
          handleInstalmentStatusChange={handleInstalmentStatusChange}
          handleOpenModal={handleOpenModal}
          errorMessages={errorMessages}
        />
      ) : (
        <CardLayout
          paymentRequests={paymentRequests}
          handleStatusChange={handleStatusChange}
          handleOpenModal={handleOpenModal}
          downloadFile={downloadFileForRequest}
          errorMessages={errorMessages}
          loadingStates={loadingStates}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
        />
      )}

      <ConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await updateRequestStatus(pendingRequest, pendingStatus);
        }}
        type={
          confirmationMessageType === "LateFee" ? "lateFee" : "paymentSubmitted"
        }
        message={
          confirmationMessageType === "LateFee" ? (
            <>
              Late fee of{" "}
              <strong>
                {pendingRequest?.amountCurrency}{" "}
                {formatCurrency(confirmationData.lateFee)}{" "}
              </strong>
              will be added to the user's next order. Are you sure you want to
              proceed?
            </>
          ) : confirmationMessageType === "PaymentSubmitted" ? (
            <>
              The new due date for Flex pay will be{" "}
              <strong>{confirmationData.newDueDate.toDateString()}</strong>. Are
              you sure you want to proceed?
            </>
          ) : null
        }
      />

      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={paymentRequests.length}
        onPageChange={handlePageChange}
      />
      <RequestModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRequest={selectedRequest}
      />
    </>
  );
}
